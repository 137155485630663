import React from 'react'
import { graphql } from 'gatsby'
import StickyImage from 'components/StickyImage'
import StickyCard from 'components/StickyCard'
import HubspotFormWrap from 'components/HubspotFormWrap'
import Layout from 'components/Layout'
import PostTitleBlock from 'components/PostTitleBlock'
import Postbuilder from 'components/Postbuilder'
import { isObjectEmpty } from 'js/utils'

export default function InsightsSingleGeneral({ data }) {
  const page = data.wpPost
  return (
    <Layout
      bodyClassName='resource-template-default single single-resource postid-26965 twitter-ana-overcome-data-and-measurement-challenges app-data index-data singular-data single-data single-resource-data single-resource-twitter-ana-overcome-data-and-measurement-challenges-datablog-template-default single single-blog postid-27063 biggest-changes-marketing-measurement app-data index-data singular-data single-data single-blog-data single-blog-biggest-changes-marketing-measurement-data'
      page={page}
    >
      <Main page={page} />
    </Layout>
  )
}

const Main = ({ page }) => {
  const { postImageInner, postStickyCard, postHubspotForm, postbuilder } = page
  const { imageInner } = postImageInner
  return (
    <section className='single single--light single-resource multivariate-grid multivariate-grid--horizontal-clip insights'>
      <div className='single-resource__container multivariate-grid__container'>
        <div className='single-resource__row multivariate-grid__row'>
          <div className='single-resource__column multivariate-grid__column'>
            <PostTitleBlock page={page} />
            {!isObjectEmpty(postHubspotForm) && (
              <HubspotFormWrap content={postHubspotForm} />
            )}
            {postbuilder?.postbuilder && (
              <Postbuilder content={postbuilder.postbuilder} />
            )}
          </div>
          <div className='single-resource__column multivariate-grid__column'>
            {imageInner && <StickyImage image={imageInner} />}
            {!imageInner && <StickyCard content={postStickyCard} />}
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  query ($slug: String!) {
    wpPost(slug: { eq: $slug }) {
      title
      ...SeoPost
      ...RelatedBlockPost
      ...FormattedPostDate
      categories {
        nodes {
          name
          slug
        }
      }
      ...PostImage
      ...PostImageInner
      ...PostStickyCard
      ...PostHubspotForm
      ...PostbuilderPost
    }
  }
`
