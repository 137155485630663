import React from 'react'
import ImageWrap from 'components/ImageWrap'

export default function StickyImage({ image }) {
  return (
    <section className='sticky-card sticky-card--image'>
      <div className='figure'>
        <ImageWrap image={image} />
      </div>
    </section>
  )
}
